import React from 'react';
import './App.css';
import {
    ClerkProvider,
    SignedIn,
    SignedOut,
    OrganizationProfile,
    OrganizationSwitcher,
    UserProfile,
    UserButton,
    ClerkLoaded,
    ClerkLoading, RedirectToSignIn
} from '@clerk/clerk-react';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import Dashboard from './views/dashboard';
import Navbar from './navbar';
import Back from './back';
import NoOrgFound from './views/NoOrgFound';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Dashboard/>,
    },
    {
        path: '/profile',
        element: (<div style={{maxHeight: '90vh', overflow: "auto"}}>
            <Back/>
            <UserProfile/>
        </div>),
    },
    {
        path: '/organization',
        element: (<div style={{maxHeight: '90vh', overflow: "auto"}}>
            <Back/>
            <OrganizationProfile/>
        </div>),
    },
    {
        path: '/button',
        element: <UserButton/>,
    },
    {
        path: '/navbar',
        element: (<>
            <Back/>
            <Navbar/>
        </>),
    },
    {
        path: '/switch',
        element: <OrganizationSwitcher hidePersonal={true} createOrganizationUrl={''}
                                       createOrganizationMode={'navigation'}/>,
    },
    {
        path: '/no-org-found',
        element: <NoOrgFound/>,
    },
    {
        path: '*',
        element: <div>Not Found</div>,
    },
]);

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw 'Missing Publishable Key'
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

// TODO: Add sign up page
function App() {
    return (
        <div className="App">
            <ClerkProvider publishableKey={clerkPubKey}>
                <ClerkLoaded>
                    <SignedIn>
                        <RouterProvider router={router}/>
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn/>
                    </SignedOut>
                </ClerkLoaded>
                <ClerkLoading>
                    <span className="loader"></span>
                </ClerkLoading>
            </ClerkProvider>
        </div>
    );
}

export default App;

import Retool from 'react-retool';
import {useOrganization, useOrganizationList, useUser} from '@clerk/clerk-react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const Dashboard = () => {
    const {user: userData} = useUser();
    const navigate = useNavigate()
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [environment, setEnvironment] = useState('production')

    const {organizationList, isLoaded, setActive} = useOrganizationList();
    const {
        organization: currentOrganization
    } = useOrganization();


    useEffect(() => {
        if (!currentOrganization && organizationList && organizationList.length > 0) {
            setActive({organization: organizationList[0].organization})
        }
    }, [currentOrganization, organizationList, isLoaded, setActive])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)

    }, [loading])

    const onNavigate = (e) => {
        if (e && e.type === 'retool-navigate') {
            navigate(e.route)
        } else if (e && e.type === 'retool-environment') {
            setEnvironment(e.environment)
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        } else if (e && e.type === 'retool-org-switch') {
            console.log(e);
            let new_org_id = e.org_id
            let org = organizationList.find(o => o.organization.publicMetadata?.org_id === new_org_id)
            setActive({organization: org.organization})
        }
    }

    useEffect(() => {
        if (userData) {
            let orgs = userData.organizationMemberships
            if (!orgs || orgs.length === 0) {
                navigate('/no-org-found')
                return
            }
            console.log(userData);
            setUser(JSON.parse(JSON.stringify(userData)))
        }
    }, [userData])

    if (!user) {
        return <span className='loader'></span>
    }


    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            overflow: 'hidden'
        }}>
            {loading && <span className='loader'></span>}
            <div style={{
                opacity: loading ? 0 : 1,
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                transition: 'opacity 1s linear',
                boxSizing: 'border-box'
            }}>
                <Retool
                    url={'https://verisoul.retool.com/embedded/public/c5ff87c6-0e5c-42c9-83e0-8324b25d08dd?_environment=' + environment} // live
                    // url = {'https://verisoul.retool.com/editor/3c5445d0-b32e-11ee-9eb8-0f789084b71f/v2/Email%20Intelligence%20AI?_releaseVersion=latest'} // testing
                    data={{user}}
                    height={'100%'}
                    width={'100%'}
                    onData={onNavigate}
                    style={{border: 'none', margin: 0, padding: 0, boxSizing: 'border-box'}}
                />
            </div>
        </div>
    );
}

export default Dashboard;
